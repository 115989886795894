import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ProjectLink from "./projectLink"

export default function Project({ project }) {
  const { projectSectionImage, logoWhite, title, slug } = project.frontmatter

  return (
    <div className="grid grid-cols-2 md:grid-cols-6 gap-8 2xl:mx-28 py-8">
      <GatsbyImage
        image={projectSectionImage.childImageSharp.gatsbyImageData}
        className="col-span-full rounded-xl overflow-hidden"
        alt={`homepage of ${title}`}
      />
      <img
        src={logoWhite.publicURL}
        className="col-span-full md:col-span-3 w-40 row-span-3"
        alt={`logo for ${title}`}
      />
      {/* <p  className="col-span-full md:col-start-3 md:col-end-7 text-neutral-300">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium sint, error quis illum commodi minus quasi ullam et magni eos laudantium eligendi iusto fugiat itaque eum cupiditate asperiores voluptatibus voluptatum odit aspernatur? Nemo dolor exercitationem ipsa alias eveniet? At suscipit minima ipsum deleniti earum! Laboriosam ducimus iusto amet dolorum? Ipsum.</p> */}
      <div className="col-span-full md:col-start-4 md:col-end-7 md:text-right">
        <ProjectLink to={`/projects/${slug}`} text="Project details" />
        <ProjectLink to="/contact" text="Get started" />
      </div>
    </div>
  )
}
