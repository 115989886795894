import useWindowHeight from "../components/useWindowHeight"
// import { motion } from "framer-motion"

//components
import Layout from "../components/Layout"
import HeroSection from "../components/HeroSection"
import Seo from "../components/Seo"
import ProjectsSection from "../components/ProjectsSection"
import ServicesSection from "../components/ServicesSection"
import ContactSection from "../components/ContactSection"
import TechnologiesUsedSection from "../components/TechnologiesUsed"
import AboutMeSection from "../components/AboutMeSection"

const IndexPage = () => {
  // const [loading, setLoading] = useState(false)

  const height = useWindowHeight()

  // useEffect(() => {
  //   loading
  //     ? document.querySelector("body").classList.add("loading")
  //     : document.querySelector("body").classList.remove("loading")
  // }, [loading])

  return (
    <>
      {/* {loading ? (
        <Loader setLoading={setLoading} key="loader" height={height} />
      ) : (
         */}
      <Layout navBarType="gray" page="home" isBgDark={true} loading={false}>
        <Seo title="Home" />
        <HeroSection viewportHeight={height} />

        <AboutMeSection />
        <ServicesSection />
        <ProjectsSection />
        {/* <CtaSection /> */}
        <TechnologiesUsedSection />

        <ContactSection />
      </Layout>
      {/* )} */}
    </>
  )
}

export default IndexPage
