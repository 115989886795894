import { graphql, useStaticQuery } from "gatsby"

import Service from "./Service"

// transition duration-500 ease-in-out transform hover:-tranneutral-y-2
// border border-blue-500

// This services section can work for 6 things. Or it can work for a features section without the hover elements.

const Intro = ({ title, headline, headline2, text }) => {
  return (
    <div className="flex flex-col items-center text-center pt-24 md:pt-32 md:pb-12  lg:pb-24 bg-white">
      <p className="text-sm max-w-xl mb-8 uppercase tracking-wider font-bold text-neutral-500">
        {title}
      </p>
      <h2 className="text-5xl lg:text-6xl mb-12 font-bold font-display">
        <div>{headline}</div>
        <div className="block">{headline2}</div>
      </h2>
      <p className="text-lg lg:text-2xl sm:w-2/3 lg:w-1/2">{text}</p>
    </div>
  )
}

const ServicesSection = () => {
  const serviceImageQuery = useStaticQuery(graphql`
    query servicesSectionData {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "service-section" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(height: 600)
            }
            name
          }
        }
      }
    }
  `)

  const images = serviceImageQuery.allFile.edges

  const services = [
    {
      category: "Design & Development",
      heading: "You dream it.",
      heading2: "I build it.",
      text: "Get a scalable website with tailored experiences that capture your customer's attention and inspire sales.",
      id: "1",
      // cta : "Start building",
      cta: "Tell me about your project",
      link: "/services",
    },
    {
      category: "Migration",
      heading: "Bring your vision to Shopify.",
      text: "Join the over 1.7 million businesses that trust Shopify. Smoothly. Quickly. Easily.",
      id: "2",
      cta: "Switch to Shopify",
      link: "/services",
      imageRight: true,
    },
    {
      category: "Optimization",
      heading: "Sell smarter.",
      heading2: "Sell more.",
      text: "Engage your customers the right way with speed-optimized, data-driven, personalized experiences.",
      id: "3",
      cta: "Optimize your store",
      link: "/services",
    },
  ]

  return (
    <section className="w-full bg-white">
      <Intro headline="Let's build your vision, together." />
      {services.map((service, id) => (
        <Service key={id + service.id} images={images} {...service} />
      ))}
    </section>
  )
}

export default ServicesSection
