import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

function ProjectLink({ to, text }) {
  return (
    <Link to={to} className="mr-8 md:ml-8 text-neutral-50 group">
      <span className="hover:underline">{text}</span>
      <FontAwesomeIcon
        icon={faChevronRight}
        className="ml-2 h-3 group-hover:translate-x-3 transition-transform"
      />
    </Link>
  )
}

export default ProjectLink
