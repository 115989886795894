import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SocialLinks from "./SocialLinks"

export default function AboutMeSection() {
  const data = useStaticQuery(graphql`
    query aboutMeData {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "about-me" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  //   const image =
  const image = data.allFile.edges[0].node.childImageSharp.gatsbyImageData

  return (
    <section className="container mx-auto max-w-6xl lg:mt-24 lg:mb-10 p-14 flex flex-wrap lg:justify-around bg-neutral-900 lg:rounded-xl">
      <div className="basis-full lg:basis-3/12 flex flex-col items-center justify-center">
        <div className="w-40 sm:w-44 mb-14 mx-auto overflow-hidden">
          <GatsbyImage
            image={image}
            alt="Carlos J Hernandez, a man of age 33, brown eyes, and brown hair."
          />
        </div>
        <div className="mb-14">
          <SocialLinks />
        </div>
      </div>
      <div className="lg:basis-8/12">
        <h3 className="text-white text-3xl md:text-4xl mb-16 leading-10 font-bold">
          Hi, I'm Carlos. I'm a Front End Developer that specializes on
          Ecommerce.
        </h3>
        <p className="text-2xl md:text-3xl text-neutral-400 leading-10 font-bold">
          I help businesses reach more customers and sell more through tailored
          shopping experiences.
        </p>
      </div>
    </section>
  )
}
