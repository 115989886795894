import { Link } from "gatsby"
import { motion } from "framer-motion"

const transition = { duration: 0.5, ease: [0.6, 0.01, -0.05, 0.9] }

const BorderButton = ({ message, link }) => (
  <Link role="button" to={link ? link : "/schedule"} className="relative">
    <button
      className="absolute rounded-full bg-white text-black text-xl font-bold "
      style={{
        width: 150,
        height: 80,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div className="py-6">{message}</div>
    </button>
    <motion.div
      initial={{ opacity: 0 }}
      whileHover={{ scaleY: 1.2, scaleX: 1.1, opacity: 1 }}
      //   transition={transition}
      className="absolute rounded-full border-white border-2"
      style={{ width: 150, height: 80 }}
    ></motion.div>
  </Link>
)

export default BorderButton
