import { graphql, useStaticQuery } from "gatsby"
import { Tabs, TabList, TabPanels, TabPanel } from "@reach/tabs"
import Project from "./Project"
import ProjectsSectionNavIcon from "./ProjectsSectionNavIcon"

export default function ProjectsDisplay() {
  const carousel = useStaticQuery(graphql`
    query tabsCarouselData {
      projects: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "project" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          frontmatter {
            services
            slug
            showSection
            title
            projectPageHeadline
            logo {
              publicURL
            }
            projectSectionImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            logoWhite {
              publicURL
            }
          }
        }
      }
    }
  `)

  const projects = carousel.projects.nodes

  return (
    <Tabs>
      <TabPanels>
        {projects.map((project, idx) => (
          <TabPanel key={idx}>
            <Project project={project} />
          </TabPanel>
        ))}
      </TabPanels>
      <TabList className="flex justify-center">
        {projects.map((project, idx) => (
          <ProjectsSectionNavIcon key={idx} />
        ))}
      </TabList>
    </Tabs>
  )
}
