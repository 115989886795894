import { useState, useEffect } from "react";

const useWindowHeight = () => {

    const [height, setHeight] = useState( '100vh' );

    useEffect( () => {
        const handleResize = () => setHeight( window.innerHeight > 800 ? window.innerHeight : 700 );
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    })

    return height

}

export default useWindowHeight