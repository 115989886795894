import { Tab } from "@reach/tabs"

export default function ProjectsSectionNavIcon(props) {
  // `isSelected` comes from `TabList` cloning the `CoolTab`.
  const { isSelected } = props

  // make sure to forward *all* props received from TabList
  return (
    <Tab className="" {...props}>
      <div
        className={`bg-neutral-900 active:relative active:top-px active:left-px cursor-pointer rounded-full h-6 w-6 m-2 outline-0 flex items-center justify-center focus-visible:bg-neutral-300`}
        aria-current={isSelected}
      >
        <span
          className={`${
            isSelected
              ? "bg-gradient-to-r from-teal-400 to-cyan-600"
              : "bg-neutral-100 hover:scale-125 transition-transform"
          } inline-block rounded-full h-5 w-5`}
        />
      </div>
    </Tab>
  )
}
