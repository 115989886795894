import BorderButton from "./borderButton"
import ShopifyPartnersLogo from "../images/shopify-partners-w.png"

import { motion } from "framer-motion"

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export default function HeroSection({ viewportHeight }) {
  return (
    <section className={"w-full z-20 relative bg-neutral-900"}>
      <div
        className="container mx-auto flex flex-col items-start justify-center intro-message-section xl:px-36"
        style={{ height: viewportHeight }}
      >
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: transition }}
          className="font-display"
        >
          <div className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl  text-white mb-12">
            Helping businesses reach{" "}
            <strong className="text-cyan-400">more customers</strong> through
            tailored experiences.
          </div>
        </motion.h1>

        <div>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.4, ...transition },
            }}
            className="text-xl max-w-3xl text-white mb-12 text-neutral-200"
          >
            Bring your vision to life with Shopify stores that help you reach
            your target customers and inspire action.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.6, ...transition },
            }}
            className="mb-12"
          >
            <img src={ShopifyPartnersLogo} className="w-48" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.8, ...transition },
            }}
          >
            <BorderButton message="Let's talk" link="#contact" />
          </motion.div>
        </div>
        {/* <ScrollDownBottom /> */}
      </div>
    </section>
  )
}
