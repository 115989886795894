import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

function Service({
  heading,
  heading2,
  category,
  text,
  images,
  id,
  cta,
  imageRight,
  link,
}) {
  let featuredImage

  images.forEach(item => {
    if (item.node.name === id) {
      featuredImage = item.node.childImageSharp.gatsbyImageData
    }
  })

  return (
    // <Link to={link} className="hover:cursor-pointer">
    <div className="mb-2 bg-white lg:mb-12 xl:mb-24">
      <div
        className="flex flex-wrap items-center justify-between container mx-auto"
        style={{
          flexDirection: imageRight ? "row-reverse" : "row",
        }}
      >
        {/* <div className="py-8 md:w-3/5 lg:mb-0 sm:px-4 lg:px-6 lg:px-16 xl:px-24"> */}
        <motion.div
          // variants={container}
          // initial="hidden"
          // whileInView="show"
          className="flex flex-col w-full lg:w-1/2 xl:w-2/5 pt-8 lg:px-4 xl:px-6"
        >
          <motion.h2
            //   variants={item}
            className="text-xs uppercase mb-4 tracking-wider text-neutral-500"
          >
            {category}
          </motion.h2>
          <motion.div
            //   variants={item}
            className="text-3xl lg:text-4xl mb-6 font-bold font-display"
          >
            <h3>{heading}</h3>
            <h3>{heading2}</h3>
          </motion.div>
          <motion.p
            //   variants={item}
            className="text-base lg:text-lg text-neutral-700 mb-6"
          >
            {text}
          </motion.p>
          <motion.div
            // variants={item}
            className="flex space-between group"
          >
            <Link
              to="#contact"
              className="text-lg text-cyan-600  mb-6 hover:underline"
            >
              {cta}
              <FontAwesomeIcon
                icon={faChevronRight}
                className="ml-2 h-4 group-hover:translate-x-2 transition-transform"
              />
            </Link>
          </motion.div>
        </motion.div>
        <div className="lg:w-1/2 xl:w-3/5 flex align-center justify-center">
          <GatsbyImage image={featuredImage} alt={`Image for ${category}`} />
        </div>
      </div>
    </div>
    // </Link>
  )
}

export default Service
