import ProjectsDisplay from "./ProjectsDisplay"

const ProjectsSection = () => {
  return (
    <section className="w-full bg-neutral-900">
      <div className="container mx-auto py-24">
        <div className="flex items-center flex-col text-white text-center">
          <p className="text-lg max-w-xl mb-8 uppercase">work</p>
          <h2 className="text-5xl lg:text-6xl mb-8">Built for growth</h2>
        </div>
        {/* <h2 className="text-8xl text-white w-full md:text-center tracking-wide pb-12" style={{lineHeight: '1.1'}}>{ copyData.title }</h2> */}
        <ProjectsDisplay />
      </div>
    </section>
  )
}

export default ProjectsSection
