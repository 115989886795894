import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faReact,
  faJsSquare,
  faCss3,
  faNodeJs,
  faShopify,
  faHtml5,
} from "@fortawesome/free-brands-svg-icons"

import SectionIntroVersion2 from "./SectionIntroVersion2"

import GatsbyLogo from "../images/gatsby-icon-b.png"
import TailwindLogo from "../images/tailwind-logo.png"
import FramerLogo from "../images/framer-logo.png"

function Card({ icon, text }) {
  return (
    <div className="flex flex-col p-8 align-center justify-center text-center rounded-xl border border-1 border-black">
      <div className="text-2xl">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>{text}</div>
    </div>
  )
}

function CardImage({ image, text }) {
  return (
    <div className="flex flex-col p-8 items-center justify-center text-center rounded-xl border border-1 border-black">
      <img
        src={image}
        style={{
          height: "24px",
          display: "block",
        }}
        className="mb-1"
      />
      <div>{text}</div>
    </div>
  )
}

function TechnologiesUsedSection() {
  return (
    <div className="container mx-auto">
      <SectionIntroVersion2 headline="Technologies" />
      <div className="flex items-center justify-center gap-4 sm:w-3/4 xl:w-3/5 mx-auto flex-wrap mb-48">
        <Card icon={faHtml5} text="HTML5" />
        <Card icon={faCss3} text="CSS3" />
        <Card icon={faJsSquare} text="Javascript" />
        <Card icon={faReact} text="React" />
        <Card icon={faNodeJs} text="Node.js" />
        <Card icon={faShopify} text="Shopify" />
        <CardImage image={GatsbyLogo} text="Gatsby" />
        <CardImage image={TailwindLogo} text="TailwindCSS" />
        <CardImage image={FramerLogo} text="Framer Motion" />
      </div>
    </div>
  )
}

export default TechnologiesUsedSection
